"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = ["role"];
var _hoisted_2 = {
    key: 1,
    class: "ocm-headline2 border-b-2 border-b-gray-30 pb-3.5 pl-4 pr-16 pt-4 tablet-down:hyphens-auto tablet-down:break-words",
    "data-test-modal-headline": ""
};
var _hoisted_3 = {
    key: 2,
    class: "flex justify-end px-4 pb-4"
};
var vue_3 = require("vue");
var CloseButton_vue_1 = require("../CloseButton/CloseButton.vue");
var SharedButton_vue_1 = require("../SharedButton.vue");
var services_modal_1 = require("../../../services/services.modal");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Vue3Modal',
    props: {
        ifShowIsOpen: { type: Boolean, default: true },
        wide: { type: Boolean },
        fullMaxHeight: { type: Boolean },
        narrow: { type: Boolean },
        preventDismiss: { type: Boolean },
        closeActionButton: { type: Boolean },
        closeActionButtonText: { default: "Ok" },
        headline: {},
        noScrollbar: { type: Boolean },
        noBodyPadding: { type: Boolean }
    },
    emits: ["closing", "closed", "clicked"],
    setup: function (__props, _a) {
        var __expose = _a.expose, __emit = _a.emit;
        var props = __props;
        var emit = __emit;
        var slideIn = (0, vue_3.ref)(true);
        (0, vue_3.watch)(function () { return props.ifShowIsOpen; }, function (newVal) { return (slideIn.value = newVal); });
        var timeout = (0, vue_3.ref)();
        (0, vue_3.onBeforeUnmount)(function () {
            clearTimeout(timeout.value);
        });
        var close = function (triggeredBy) {
            if (triggeredBy === void 0) { triggeredBy = ""; }
            slideIn.value = false;
            emit("closing", triggeredBy);
            timeout.value = window.setTimeout(function () {
                emit("closed");
            }, services_modal_1.animationDurationMs);
        };
        __expose({ close: close });
        return function (_ctx, _cache) {
            var _a;
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(vue_2.Teleport, { to: "body" }, [
                (0, vue_2.withDirectives)((0, vue_2.createElementVNode)("div", {
                    ref: "modalOverlayEl",
                    class: (0, vue_2.normalizeClass)(["tw fallback-modal modalOverlay animated fixed left-0 top-0 z-[999] h-full w-screen items-end justify-center lg:items-center", [_ctx.$attrs.class, slideIn.value ? 'modalFadeIn' : 'modalFadeOut']]),
                    style: (0, vue_2.normalizeStyle)({ '--animationDuration': "".concat((0, vue_2.unref)(services_modal_1.animationDurationMs), "ms") }),
                    onClick: _cache[3] || (_cache[3] = (0, vue_2.withModifiers)(function ($event) { return (!_ctx.preventDismiss && close('overlay')); }, ["self", "stop"]))
                }, [
                    (0, vue_2.createElementVNode)("div", {
                        class: (0, vue_2.normalizeClass)(["modal animated relative flex w-full flex-col bg-white text-black-primary", [
                                slideIn.value ? 'modalSlideInUp' : 'modalSlideOutDown',
                                {
                                    'is-narrow': _ctx.narrow,
                                    'is-wide': _ctx.wide,
                                    'max-h-full': _ctx.fullMaxHeight,
                                },
                            ]]),
                        role: (_a = _ctx.$attrs.role) !== null && _a !== void 0 ? _a : 'dialog',
                        tabindex: "-1",
                        onClick: _cache[2] || (_cache[2] = (0, vue_2.withModifiers)(function ($event) { return (_ctx.$emit('clicked', $event)); }, ["stop"]))
                    }, [
                        (!_ctx.preventDismiss)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(CloseButton_vue_1.default, {
                                key: 0,
                                class: "right-4 top-4",
                                "data-test-modal-close-button": "",
                                onClick: _cache[0] || (_cache[0] = function ($event) { return (close('closeButton')); })
                            }))
                            : (0, vue_2.createCommentVNode)("", true),
                        (_ctx.headline)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("h2", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.headline), 1))
                            : (0, vue_2.createCommentVNode)("", true),
                        (0, vue_2.createElementVNode)("div", {
                            class: (0, vue_2.normalizeClass)(["pb-4", [
                                    _ctx.noScrollbar ? 'flex flex-col overflow-y-hidden' : 'o-scrollbar overscroll-contain',
                                    {
                                        'lg-down:pb-9': !_ctx.closeActionButton,
                                        'px-4 pt-6': !_ctx.noBodyPadding,
                                    },
                                ]])
                        }, [
                            (_ctx.$slots.headerblock)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                    key: 0,
                                    class: (0, vue_2.normalizeClass)(["-mt-5 bg-gray-20 px-4 py-9", { '-mx-4': !_ctx.noBodyPadding }])
                                }, [
                                    (0, vue_2.renderSlot)(_ctx.$slots, "headerblock")
                                ], 2))
                                : (0, vue_2.createCommentVNode)("", true),
                            (0, vue_2.renderSlot)(_ctx.$slots, "default")
                        ], 2),
                        (_ctx.closeActionButton)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_3, [
                                (0, vue_2.renderSlot)(_ctx.$slots, "footer"),
                                (0, vue_2.createVNode)(SharedButton_vue_1.default, {
                                    class: "min-w-25",
                                    onClick: _cache[1] || (_cache[1] = function ($event) { return (close('actionButton')); })
                                }, {
                                    default: (0, vue_2.withCtx)(function () { return [
                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.closeActionButtonText), 1)
                                    ]; }),
                                    _: 1
                                })
                            ]))
                            : (0, vue_2.createCommentVNode)("", true)
                    ], 10, _hoisted_1)
                ], 6), [
                    [vue_2.vShow, _ctx.ifShowIsOpen]
                ])
            ]));
        };
    }
});
