"use strict";
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPdpShippingInformationEnabled = exports.memberGetsMemberShopEnabled = exports.memberGetsMemberClassicEnabled = exports.department = exports.captchaActive = exports.captchaPublickey = exports.isEpoqRecosPdpEnabled = exports.epoqRecosPlpChunkSize = exports.isEpoqRecosCartEnabled = exports.shopContextPath = exports.isClassicSite = exports.contextPath = exports.language = exports.country = void 0;
exports.country = ((_b = (_a = window.ocm) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.country) || "de";
exports.language = ((_d = (_c = window.ocm) === null || _c === void 0 ? void 0 : _c.config) === null || _d === void 0 ? void 0 : _d.language) || "de";
exports.contextPath = (_g = (_f = (_e = window.ocm) === null || _e === void 0 ? void 0 : _e.config) === null || _f === void 0 ? void 0 : _f.encodedContextPath) !== null && _g !== void 0 ? _g : "/".concat(exports.country, "-de/shop");
exports.isClassicSite = (_j = (_h = window.ocm) === null || _h === void 0 ? void 0 : _h.isClassicSite) !== null && _j !== void 0 ? _j : false;
exports.shopContextPath = exports.isClassicSite ? "/".concat(exports.country, "-de/shop") : exports.contextPath;
// Epoq
exports.isEpoqRecosCartEnabled = (_l = (_k = window.ocm) === null || _k === void 0 ? void 0 : _k.config) === null || _l === void 0 ? void 0 : _l.isEpoqRecosCartEnabled;
exports.epoqRecosPlpChunkSize = (_o = (_m = window.ocm) === null || _m === void 0 ? void 0 : _m.config) === null || _o === void 0 ? void 0 : _o.epoqRecosPlpChunkSize;
exports.isEpoqRecosPdpEnabled = (_q = (_p = window.ocm) === null || _p === void 0 ? void 0 : _p.config) === null || _q === void 0 ? void 0 : _q.isEpoqRecosPdpEnabled;
// Contact Center
exports.captchaPublickey = ((_s = (_r = window.ocm) === null || _r === void 0 ? void 0 : _r.captchaOptions) === null || _s === void 0 ? void 0 : _s.publickeyContactcenter) || "";
exports.captchaActive = (_u = (_t = window.ocm) === null || _t === void 0 ? void 0 : _t.captchaOptions) === null || _u === void 0 ? void 0 : _u.displayContactcenter;
exports.department = (_w = (_v = window.ocm) === null || _v === void 0 ? void 0 : _v.config) === null || _w === void 0 ? void 0 : _w.department;
// My Account
exports.memberGetsMemberClassicEnabled = (_z = (_y = (_x = window.ocm) === null || _x === void 0 ? void 0 : _x.config) === null || _y === void 0 ? void 0 : _y.memberGetsMemberClassicEnabled) !== null && _z !== void 0 ? _z : false;
exports.memberGetsMemberShopEnabled = (_2 = (_1 = (_0 = window.ocm) === null || _0 === void 0 ? void 0 : _0.config) === null || _1 === void 0 ? void 0 : _1.memberGetsMemberShopEnabled) !== null && _2 !== void 0 ? _2 : false;
// TUB-21694 Versand- und Retoureninformation A/B-Test
exports.isPdpShippingInformationEnabled = (_5 = (_4 = (_3 = window.ocm) === null || _3 === void 0 ? void 0 : _3.config) === null || _4 === void 0 ? void 0 : _4.isPdpShippingInformationEnabled) !== null && _5 !== void 0 ? _5 : false;
